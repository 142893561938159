import Banner from "@Models/banner.model";
import Image from "next/image";
import Link from "next/link";
import React from "react";
import Slider from "react-slick";

interface IBanner {
   imgPc: string;
   imgMb: string;
   title: string;
   url?: string;
}

interface IState {
   banners: IBanner[];
}

export default class Banners extends React.Component<{ data: Banner[] }, IState> {
   private _menus = [
      {
         title: "Feed",
         icon: "/assets/img/menu/black/social-feed.svg",
         iconLg: "/assets/img/menu/black/social-feed.svg",
         path: "/feed",
      },
      {
         title: "Hỏi đáp",
         icon: "/assets/img/menu/orange/qna.svg",
         iconLg: "/assets/img/menu/orange/qna.svg",
         path: "/hoi-dap",
      },
      {
         title: "Shop",
         icon: "/assets/img/menu/black/shop.svg",
         iconLg: "/assets/img/menu/black/shop.svg",
         path: "/shop",
      },
      {
         title: "Dự án hoàn thiện",
         icon: "/assets/img/menu/orange/my-home.svg",
         iconLg: "/assets/img/menu/orange/my-home.svg",
         path: "/thu-vien-thiet-ke",
      },
      {
         title: "Thư viện ảnh",
         icon: "/assets/img/menu/orange/image-by-space.svg",
         iconLg: "/assets/img/menu/black/image-by-space.svg",
         path: "/thu-vien-anh",
      },
      {
         title: "Nhận tư vấn",
         icon: "/assets/img/menu/black/conclusion.svg",
         iconLg: "/assets/img/menu/orange/conclusion.svg",
         path: "/cau-hoi-khao-sat",
      },
      {
         title: "Công ty nội thất",
         icon: "/assets/img/menu/orange/partner.svg",
         iconLg: "/assets/img/menu/black/partner.svg",
         path: "/cac-cong-ty-noi-that",
      },
      {
         title: "Dự toán",
         icon: "/assets/img/menu/black/estimate-calc.svg",
         iconLg: "/assets/img/menu/orange/estimate-calc.svg",
         path: "/bao-gia",
      },
   ];
   constructor(props: any) {
      super(props);
      this.state = {
         banners: [
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/homepage-slide-web-1.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/homepage-slide-mobile-1.webp",
               title: "Space T",
               url: "/shop",
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-3.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-3.webp",
               title: "Quy trình kết nối cùng Space T",
               url: "/quy-trinh-ket-noi?internal=home-banner-2-v2",
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-4.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-4.webp",
               title: "Space T",
               url: "/bao-gia?internal=home-banner-3-v2",
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-5.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-5.webp",
               title: "Công cụ Dự toán Chi phí Thi công Nội thất - Space T",
               url: "/thiet-ke-2d-3d",
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-6.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-6.webp",
               title: "Miễn phí Thiết kế 2D khi Hoàn thiện Nội thất cùng Space T",
               url: `/chuong-trinh-gioi-thieu-ban`,
            },
            {
               imgPc: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-web-7.webp",
               imgMb: "https://spacet-release.s3.ap-southeast-1.amazonaws.com/img/banner/home-mobile-7.webp",
               title: "Chương trình Giới Thiệu Bạn - Nhận Thưởng Hấp Dẫn - Space T",
               url: "/blog",
            },
         ],
      };
   }
   setPreviousURL = (banner: any) => {
      if (typeof window !== "undefined") {
         if (banner && banner?.url?.includes("/cau-hoi-khao-sat")) {
            sessionStorage.setItem("previous_url", "");
            sessionStorage.setItem("previous_url", window?.location?.href);
         }
      }
   };
   render() {
      const settingsSliderBanner = {
         arrows: false,
         dots: true,
         speed: 500,
         slidesToShow: 1,
         slidesToScroll: 1,
         autoplay: true,
         autoplaySpeed: 6000,
      };
      return (
         <div className="block">
            <div className="block_banner">
               <div className="container">
                  <div className="page-banner slider mb-md-4">
                     <Slider {...settingsSliderBanner}>
                        {this.state.banners.map((banner, i) => {
                           return (
                              <div className="slider-item" key={i}>
                                 {banner.url && (
                                    <Link title="Banner" href={banner.url ?? ""} className="cover" onClick={() => this.setPreviousURL(banner)}></Link>
                                 )}
                                 <picture>
                                    <source media="(min-width: 768px)" srcSet={banner.imgPc ?? ""} />
                                    <source media="(max-width: 767px)" srcSet={banner.imgMb ?? ""} />
                                    <Image width="500" height="200" title={banner.title ?? ""} alt={banner.title ?? ""} src={banner.imgPc ?? ""} />
                                 </picture>
                              </div>
                           );
                        })}
                     </Slider>
                  </div>
                  <section className="row menu g-0 container container_custom">
                     <div className="col-12">
                        <div className="row gx-1 gy-3">
                           {this._menus.map((item, index) => (
                              <div className="col-3 col-md" key={index}>
                                 <Link className="menu-item " href={item.path}>
                                    <div className="image">
                                       <picture>
                                          <source media="(min-width: 768px)" srcSet={item.iconLg ?? item.icon} />
                                          <source media="(max-width: 767px)" srcSet={item.icon ?? ""} />
                                          <img title={item.title ?? ""} alt={item.title ?? ""} src={item.icon ?? ""} />
                                       </picture>
                                       <div className="cover"></div>
                                    </div>

                                    <span>{item.title}</span>
                                 </Link>
                              </div>
                           ))}
                        </div>
                     </div>
                  </section>
               </div>
            </div>
         </div>
      );
   }
}
